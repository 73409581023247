import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import Seo from "../components/seo/seo"
import { useTranslation } from "react-i18next"
import Img from 'gatsby-image'
import SvgCreateAccount from "../icons/CreateAccount"
import SvgSelectPlan from "../icons/SelectPlan"
import SvgBusinessResults from "../icons/BusinessResults"

const HeraSmartDashboard = ({ data }) => {

  const { t } = useTranslation();
  const [tab1IsVisible, setTab1] = useState(1);
  const [tab2IsVisible, setTab2] = useState(0);
  const [tab3IsVisible, setTab3] = useState(0);
  const [tab4IsVisible, setTab4] = useState(0);

  function showTab1() {
    setTab1(1);
    setTab2(0);
    setTab3(0);
    setTab4(0);
  }

  function showTab2() {
    setTab1(0);
    setTab2(1);
    setTab3(0);
    setTab4(0);
  }

  function showTab3() {
    setTab1(0);
    setTab2(0);
    setTab3(1);
    setTab4(0);
  }

  function showTab4() {
    setTab1(0);
    setTab2(0);
    setTab3(0);
    setTab4(1);
  }

  return (

  <Layout>
    <Seo
      title={t("webapp.seoTitle")}
      description={t("webapp.seoDescription")}
      seoImage={data.graphic.childImageSharp.resize.src}
    />
    {/* Sezione Header */}
    <div className="section is-medium is-relative" style={{display: 'inline-block', width: '100%', paddingBottom: '100px',overflow: 'hidden', verticalAlign: 'middle'}}>
    <svg width="100vw" height="140vh" viewBox="0 0 100vw 140vh" style={{position: "absolute", display: 'inline-block', top: "0", left: 0, fillRule: "evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round"}} xmlns="http://www.w3.org/2000/svg" space="preserve" >
        <defs>
        <linearGradient y1="364.683" id="LinearGradient" x1="-2.95224" y2="364.683" x2="2005.71" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 0)">
        <stop stop-color="#7dd1a7" offset="0"/>
        <stop stop-color="#71bdf4" offset="1"/>
        </linearGradient>
        </defs>
        <g id="Livello 1" layerName="Livello 1">
        <path d="M-1.43513+0.575764L2002.67+0.575764L2000+381.632C2000+381.632+1827.32+319.668+1447.66+379.973C1068.01+440.279+830.684+620.543+474.162+617.13C109.78+613.64-2.95224+456.261-2.95224+456.261L-1.43513+0.575764Z" opacity="1" fill="url(#LinearGradient)"/>
        </g>
      </svg>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <div className="content is-large">
              <h1 className="title is-white is-1 is-bottom-bordered-white">{t('webapp.pageTitle')}</h1>
              <h2 className="title is-4" style={{marginTop: 0}}>{t('webapp.pageSubtitle')}</h2>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <Img fluid={data.graphic.childImageSharp.fluid} alt={t('webapp.altImageWebApp')}/>
          </div>
        </div>
      </div>
    </div>
    {/* Sezione Overview Sistema Hera */}
    <div className="section is-medium">
       <div className="container">
         <div className="content is-large has-text-centered">
           <h2 className="title is-1">{t('webapp.overviewTitle')}</h2>
           <p className="subtitle is-3" style={{paddingTop: '20px'}}>{t('webapp.overviewSubtitle')}</p>
         </div>
       <div className="section">
         <div className="columns has-text-centered">
           <div className="column is-3">
            <div className="button is-outlined is-info is-medium is-fullwidth" onClick={() => showTab1()}><strong>{t('webapp.overviewButton1')}</strong></div>
           </div>
           <div className="column is-3">
            <div className="button is-outlined is-info is-medium is-fullwidth" onClick={() => showTab2()}><strong>{t('webapp.overviewButton2')}</strong></div>
           </div>
           <div className="column is-3">
            <div className="button is-outlined is-info is-medium is-fullwidth" onClick={() => showTab3()}><strong>{t('webapp.overviewButton3')} </strong></div>
           </div>
           <div className="column is-3">
            <div className="button is-outlined is-info is-medium is-fullwidth" onClick={() => showTab4()}><strong>{t('webapp.overviewButton4')}</strong></div>
           </div>
         </div>
          <div className={`${tab1IsVisible ? '' : 'is-hidden'} columns is-vcentered`} style={{paddingTop: '75px'}}>
            <div className="column is-6">
              <div className="content is-large">
                <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('webapp.o1Title')}</h2>
              </div>
              <div className="content">            
                <p>{t('webapp.o1Text1')}</p>
                  <ul>
                    <li>{t('webapp.o1Li1')}</li>
                    <li>{t('webapp.o1Li2')}</li>
                    <li>{t('webapp.o1Li3')}</li>
                  </ul>
                <p>{t('webapp.o1Text2')}</p>
              </div>
            </div>
            <div className="column is-6">
              <Img fluid={data.productivity.childImageSharp.fluid} />
            </div>
          </div>
          <div className={`${tab2IsVisible ? '' : 'is-hidden'} columns is-vcentered`} style={{paddingTop: '75px'}}>
            <div className="column is-6">
              <div className="content is-large">
                <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('webapp.o2Title')}</h2>
                </div>
              <div className="content">
                <p>{t('webapp.o2Text1')}</p>
                <ul>
                  <li><strong>{t('webapp.o2Li1')}</strong></li>
                  <li><strong>{t('webapp.o2Li2')}</strong></li>
                  <li><strong>{t('webapp.o2Li3')}</strong></li>
                </ul>
                <p>{t('webapp.o2Text2')}
                </p>
                </div>
            </div>
            <div className="column is-6">
              <Img fluid={data.consumi.childImageSharp.fluid} />
            </div>
          </div>
          <div className={`${tab3IsVisible ? '' : 'is-hidden'} columns is-vcentered`} style={{paddingTop: '75px'}}>
            <div className="column is-6">
              <div className="content is-large">
                <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('webapp.o3Title')}</h2>
                </div>
                <p>{t('webapp.o3Text1')} <strong>{t('webapp.o3TextBold')}</strong>
                </p>
            </div>
            <div className="column is-6">
              <Img fluid={data.errors.childImageSharp.fluid} />
            </div>
          </div>
          <div className={`${tab4IsVisible ? '' : 'is-hidden'} columns is-vcentered`} style={{paddingTop: '75px'}}>
            <div className="column is-6">
              <div className="content is-large">
                <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('webapp.o4Title')}</h2>
              </div>
              <div className="content">            
                <p>{t('webapp.o4Text')}</p>
              </div>
            </div>
            <div className="column is-6">
              <Img fluid={data.equipment.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
     </div>
     {/* Sezione Step per iniziare */}
     <div className="section is-medium has-light-background">
      <div className="container">
        <div className="content has-text-centered"><h2 className="title is-1 is-bottom-bordered">{t('webapp.ctaTitle')}</h2></div>
        <div className="columns" style={{paddingTop: '75px'}}>
          <div className="column is-4 has-text-centered" style={{padding: '0 50px 0 50px'}}>
          <SvgSelectPlan />
            <div className="content">
              <h4 className="title is-3">{t('webapp.cta1')}</h4>
            </div>
            {/* <div className="button is-outlined is-primary"><strong>{t('webapp.cta1Button')}</strong></div> */}
          </div>

          <div className="column is-4 has-text-centered" style={{padding: '0 50px 0 50px'}}>
          <SvgCreateAccount />
            <div className="content">
              <h4 className="title is-3">{t('webapp.cta2')}</h4>
            </div>
            {/* <div className="button is-outlined is-primary"><strong>{t('webapp.cta2Button')}</strong></div> */}
          </div>
    
          <div className="column is-4 has-text-centered" style={{padding: '0 50px 0 50px'}}>
            <SvgBusinessResults />
            <div className="content">
              <h4 className="title is-3">{t('webapp.cta3')}</h4>
            </div>
            {/* <div className="button is-outlined is-primary"><strong>{t('webapp.cta3Button')}</strong></div> */}
          </div>
        </div>
      </div>
    </div>    
    
  </Layout>
  )
}

export default HeraSmartDashboard

export const Home = graphql`
  query {
    textile_laundry: file(
      relativePath: { eq: "textile_laundry.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laundry_dashboard: file(
      relativePath: { eq: "LaundryDashboard.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    app: file(
      relativePath: { eq: "orderConfirmed.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    consumi: file(
      relativePath: { eq: "Industrial_Laundry_Costs_Consumptions.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productivity: file(
      relativePath: { eq: "Productivity_Tracker_Industrial_Laundries.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    errors: file(
      relativePath: { eq: "Laundry_Equipment_Errors_Tracking.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    equipment: file(
      relativePath: { eq: "laundry_equipment_list.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    graphic: file(
      relativePath: { eq: "Hera_Smart_Dashboard.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
